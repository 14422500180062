import { render, staticRenderFns } from "./OrgSaasOrganisationEdit.vue?vue&type=template&id=1a562d12&scoped=true"
import script from "./OrgSaasOrganisationEdit.vue?vue&type=script&lang=js"
export * from "./OrgSaasOrganisationEdit.vue?vue&type=script&lang=js"
import style0 from "./OrgSaasOrganisationEdit.vue?vue&type=style&index=0&id=1a562d12&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a562d12",
  null
  
)

export default component.exports