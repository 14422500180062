<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="allCardList">
        <iq-card>
          <template v-slot:headerTitle>
            <div style="display:flex;justify-content: space-between;align-items: center;">
              <h4 class="card-title">
                <i class="fa-solid fa-building" aria-hidden="true" style="font-size: 21px; margin-right: 15px; color: var(--iq-primary);"></i>
                Edit Your Organisation
              </h4>
              <button type="button" @click="goToOrgSaasLoginPreview" class="btn-primary border-0 rounded mt-3">
                View Login Page
              </button>
            </div>
          </template>
        </iq-card>
          <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="tab-content">
            <div class="iq-card rounded p-3 bg-white">
              <h4 class="mb-4 primary-color">
                Organisation Details
              </h4>
              <!-- Orgnanisation Name -->
              <div>
                <h5>
                  {{ cvOrgNameLabel }}
                </h5>
                <ValidationObserver ref="orgForm" >
                  <b-form>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <ValidationProvider name="Organisation Name" rules="required" v-slot="{ errors }">
                        <input v-model="vmOrganisationFormData.org_name" type="text" class="form-control" :placeholder="cvOrgNameLabel" required />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <br />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div><!-- Orgnanisation Name -->

              <!-- Primary Email Id -->
              <div>
                <h5>
                  {{ cvRegisteredEmailLabel }}&nbsp;&nbsp;<a :href="`mailto:${supportEmail}`"><i :title="`Contact ${supportEmail} to modify ${cvRegisteredEmailLabel}`" class="primary-color pointer fa fa-info-circle" aria-hidden="true"></i></a>
                </h5>
                <ValidationObserver ref="orgForm" >
                  <b-form>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <ValidationProvider name="Primary Email Id" rules="required" v-slot="{ errors }">
                        <input v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" v-model="vmOrganisationFormData.org_admin_email" type="text" class="form-control" :placeholder="cvRegisteredEmailLabel" required>
                        <input v-else v-model="vmOrganisationFormData.org_admin_email" type="text" class="form-control" :placeholder="cvRegisteredEmailLabel" required readonly/>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <br />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div><!-- Registered Email Id -->

              <!-- Mobile -->
              <div>
                <h5>
                  {{ cvMobileLabel }}
                </h5>
                <ValidationObserver ref="orgForm" >
                  <b-form>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <ValidationProvider name="Mobile Number" rules="required" v-slot="{ errors }">
                        <input v-model="vmOrganisationFormData.org_mobile" type="text" class="form-control" :placeholder="cvMobileLabel" required />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <br />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div><!-- Mobile -->

              <!-- Site Domain Name -->
              <div>
                <h5>
                  {{ cvSiteDomainNameLabel }}&nbsp;&nbsp;<a :href="`mailto:${supportEmail}`"><i :title="`Contact ${supportEmail} to modify ${cvSiteDomainNameLabel}`" class="primary-color pointer fa fa-info-circle" aria-hidden="true"></i></a>
                </h5>
                <ValidationObserver ref="orgForm" >
                  <b-form>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <ValidationProvider name="Site Domain Name" rules="required" v-slot="{ errors }">
                        <input v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" v-model="vmOrganisationFormData.org_domain" type="text" class="form-control" :placeholder="cvSiteDomainNameLabel" required />
                        <input v-else v-model="vmOrganisationFormData.org_domain" type="text" class="form-control" :placeholder="cvSiteDomainNameLabel" required readonly/>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <br />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div><!-- Site Domain Name -->

              <!-- Office Address -->
              <div>
                <h5>
                  {{ cvOfficeAddressLabel }}
                </h5>
                <ValidationObserver ref="orgForm" >
                  <b-form>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <ValidationProvider name="Office Address" rules="required" v-slot="{ errors }">
                        <input v-model="vmOrganisationFormData.bill_address" type="text" class="form-control" :placeholder="cvOfficeAddressLabel" required />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <br />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div><!-- Office Address -->

              <!-- City -->
              <div>
                <h5>
                  {{ cvCityLabel }}
                </h5>
                <ValidationObserver ref="orgForm" >
                  <b-form>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <ValidationProvider name="Office Address" rules="required" v-slot="{ errors }">
                        <input v-model="vmOrganisationFormData.bill_city" type="text" class="form-control" :placeholder="cvCityLabel" required />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <br />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div><!-- City -->

              <!-- Country Input-->
              <!-- <div>
                <h5>
                  {{ cvCountryLabel }}
                </h5>
                <ValidationObserver ref="orgForm" >
                  <b-form>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <ValidationProvider name="Office Address" rules="required" v-slot="{ errors }">
                        <input v-model="vmOrganisationFormData.bill_country" type="text" class="form-control" :placeholder="cvCountryLabel" required />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <br />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>Country Input -->

              <!-- Country Dropdown -->
              <div class="mb-4">
                <h5>
                  <label for="validationlead_country">{{cvCountryLabel}}</label>
                </h5>
                <ValidationProvider name="Country" rules="required" v-slot="{ errors }">
                  <select v-model="vmOrganisationFormData.bill_country" class="form-control" @change="setStateFormField">
                    <template>
                      <option v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="country">
                        {{country.country_name}}
                      </option>
                    </template>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div><!-- Country Dropdown -->

              <!-- Zipcode -->
              <div>
                <h5>
                  {{ cvZipCodeLabel }}
                </h5>
                <ValidationObserver ref="orgForm" >
                  <b-form>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <ValidationProvider name="Office Address" rules="required" v-slot="{ errors }">
                        <input v-model="vmOrganisationFormData.bill_zip_code" type="text" class="form-control" :placeholder="cvZipCodeLabel" required />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <br />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div><!-- Zipcode -->

              <!-- State Dropdown -->
              <div v-if="isStateDropdown">
                <h5>
                  {{ cvStateLabel }}
                </h5>
                <ValidationObserver ref="orgForm" >
                  <b-form>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <select name="bill_state" id="validationbill_state" class="form-control" v-model="vmOrganisationFormData.bill_state_code" required>
                        <option :value="GSTStateCode[vmOrganisationFormData.bill_state_code]" selected>{{GSTStateCode[vmOrganisationFormData.bill_state_code]}}</option>
                        <option :value="index" v-for="(key, index) in GSTStateCode" :key="index">{{key}}</option>
                      </select>
                      <br />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div><!-- State Dropdown -->

              <!-- State Input -->
              <div v-else>
                <h5>
                  {{ cvStateLabel }}
                </h5>
                <ValidationObserver ref="orgForm" >
                  <b-form>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <ValidationProvider name="Office State" rules="required" v-slot="{ errors }">
                        <input v-model="vmOrganisationFormData.bill_state" type="text" class="form-control" :placeholder="cvStateLabel" required />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <br />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div><!-- State Input -->

              <!-- GST Number -->
              <div>
                <h5>
                  {{ cvGSTNumberLabel }}
                </h5>
                <ValidationObserver ref="orgForm" >
                  <b-form>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <ValidationProvider name="GST Number" rules="required" v-slot="{ errors }">
                        <input v-model="vmOrganisationFormData.bill_gstin" type="text" class="form-control" :placeholder="cvGSTNumberLabel" required />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <br />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div><!-- GST Number -->

              <button type="button" @click="saveSaasOrgDetails" class="d-flex btn-primary border-0 px-3 py-2 rounded mt-3 mb-2">
                Save
              </button>

              <div class="mt-5">
                <h5>
                  Domain Validation
                </h5>
                <button v-if="vmOrganisationFormData.org_domain_is_validated == 0" @click="goToOrgDomainValidate" type="button" class="d-flex btn-primary border-0 px-3 py-2 rounded mt-3">
                  Validate Your Domain
                </button>
                <button v-else-if="vmOrganisationFormData.org_domain_is_validated == 1" @click="goToOrgDomainValidate" type="button" class="d-flex btn-primary border-0 px-3 py-2 rounded mt-3">
                  HTTP Validation Completed
                </button>
                <button v-else-if="vmOrganisationFormData.org_domain_is_validated == 2" @click="goToOrgDomainValidate" type="button" class="d-flex btn-primary border-0 px-3 py-2 rounded mt-3">
                  HTTPS/SSL certificate generated
                </button>
                <button v-else type="button" class="d-flex btn-primary border-0 px-3 py-2 rounded mt-3">
                  Domain Validation Completed
                </button>
              </div>
            </div>
        </div>
      </b-col>

      <b-col class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="tab-content">
          <div class="iq-card rounded p-3">
            <h4 class="mb-4 primary-color">
              Organisation Branding
            </h4>
            <!-- Profile Picture -->
            <div>
              <h5>
                Profile Pic
              </h5>
              <hr>
              <b-form>
                <div class="form-group row align-items-center">
                  <div class="col-md-12">
                    <div class="profile-img-edit border border-dark" style="height: 150px; width: 150px; border-radius: 50%; ">
                      <img v-if="vmProfilePic" :src="vmProfilePic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: contain; overflow: hidden; border-radius: 50%; " />
                      <i v-else class="fas fa-orgersity d-flex justify-content-center align-items-center rounded-circle " style="font-size: 5rem; height:100%; "></i>
                      <label class="p-image">
                        <i class="ri-pencil-line upload-button" style="cursor: pointer" ></i>
                        <input class="file-upload" type="file" accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0;" @change="openImageDialog($event, 'Profile Pic')" />
                      </label>
                    </div>
                  </div>
                  <span class="text-danger" ref="proPicError"></span>
                  </div>
                  <button type="button" class=" p-2 mr-3 mb-2 rounded btn btn-primary border-0 "  :disabled="cvProfilePicError || cvProfilePicLoading" @click="imageEdit('Profile Pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvProfilePicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Save Profile Pic</span>
                  </button>
                  <button v-if="vmProfilePic" type="button" class="p-2 mb-2 rounded btn btn-primary border-0 " :disabled="cvProfilePicError || cvProfilePicLoading"  @click="imageRemove('profile_pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvDelProfilePicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Remove Profile Pic</span>
                  </button>
              </b-form>
            </div><!-- Profile Picture -->

            <!-- Cover Picture -->
            <div class="mt-4">
              <h5>
                Cover Pic
              </h5>
              <hr>
              <b-form>
                <div class="form-group row align-items-center">
                  <div class="col-md-12">
                    <div class="profile-img-edit border" style="height: 250px; width: 100%;">
                      <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: contain; overflow: hidden;" />
                      <i v-else class="fas fa-orgersity d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                      <div class="p-image">
                        <i class="ri-pencil-line upload-button"></i>
                        <input class="file-upload" type="file" accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0;" @change="openImageDialog($event, 'Cover Pic')" />
                      </div>
                      </div>
                    </div>
                    <span class="text-danger" ref="coverPicError"></span>
                  </div>
                  <button type="button" class="p-2 mr-3 rounded btn btn-primary border-0 " @click="imageEdit('Cover Pic')" >
                    <b-spinner small class="ml-4 mr-4" v-if="cvCoverPicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else> Save Cover Pic</span>
                  </button>
                  <button v-if="vmCoverPic" type="button" class="p-2 mt-2 rounded btn btn-primary border-0 " :disabled="cvProfilePicError || cvProfilePicLoading"  @click="imageRemove('cover_pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvDelProfilePicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Remove Cover Pic</span>
                  </button>
                </b-form>
            </div><!-- Cover Picture -->

            <!-- About Us Description -->
            <div class="mt-4">
              <h5>
                About Us Description
              </h5>
              <hr>
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <vue-editor v-model="vmOrganisationFormData.org_desc">
                  </vue-editor>
                  <button @click="saveSaasOrgDetails" type="button" class="d-flex btn-primary border-0 px-3 py-2 rounded mt-3" >
                    Save
                  </button>
                </div>
              </div>
            </div><!-- About Us Description -->
          </div>
        </div>
      </b-col>

      <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
        <div v-html="toastMsg">
        </div>
      </b-toast>
    </b-row>
  </b-container>
</template>
<style scoped >
  .iq-card-stretch{
    border-radius: 20px;
  }
  .followButton{
    min-height: unset;
    max-height: 50px;
  }
  .iq-card{
    background: transparent !important;
  }
</style>
<script>
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission"
import { VueEditor } from "vue2-editor"
import GSTStateCode from "../../../FackApi/json/GSTStateCode.json"
import CropImage from "../../../components/cropImage.vue"
import ApiResponse from "../../../Utils/apiResponse"
import { Images } from "../../../FackApi/api/image"
import { OrgSaas } from "../../../FackApi/api/orgSaas.js"
import { Countries } from "../../../FackApi/api/country.js"

export default {
  name: "OrgSaasOrganisationEdit",
  data () {
    return {
      cvOrgNameLabel: "Official Name",
      cvOfficeAddressLabel: "Office Address",
      cvRegisteredEmailLabel: "Primary Email",
      cvSiteDomainNameLabel: "Whitelabled Domain Name on GIDE.AI",
      cvGSTNumberLabel: "GST/VAT/Tax Number",
      cvMobileLabel: "Mobile",
      vmOrganisationProPicImage: null,
      cvCityLabel: "City",
      cvZipCodeLabel: "Zipcode",
      cvDelProfilePicLoading: false,
      vmOrganisationCoverPicImage: null,
      propStencilSize: null,
      fileSrc: "",
      propCropImageDialogOpen: false,
      updateImageType: null,
      propCropDialogSize: "md",
      cvStateLabel: "State",
      cvCountryLabel: "Country",
      GSTStateCode: GSTStateCode,
      vmCoverPic: "",
      cvCoverPicLoading: false,
      vmProfilePic: "",
      cvProfilePicError: false,
      cvProfilePicLoading: false,
      vmOrganisationFormData: {},
      apiName: "orgsaas_organisation_view",
      showToast: false,
      cvOrgId: null,
      cvOrgType: null,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Organisation Edit",
      oldDomain: null,
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL,
      cvCountryOptions: [],
      isStateDropdown: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  components: {
    VueEditor,
    CropImage
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }

    // The admin needs to access this page in order to create or edit organisations
    if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
      return
    }

    this.orgType = this.$route.params.org_type
    this.cvOrgId = this.$route.params.org_id

    if (!(this.userData?.modules?.organisations &&
      this.userData?.modules?.organisations && this.userData.modules.organisations[this.cvOrgId] &&
      this.userData.modules.organisations[this.cvOrgId].org_id == this.cvOrgId)
    ) {
      // Not an owner of this organisation. Unauth Access. So Send back to home
      this.$router.push("/home")
    }
  },
  mounted () {
    if (this.$route.params.org_id) {
      this.cvOrgId = this.$route.params.org_id
    }
    if (this.$route.params.org_type) {
      this.cvOrgType = this.$route.params.org_type
    }
    this.loadRequiredData()
  },
  methods: {
    /**
     * saveSaasOrgDetails
     */
    async saveSaasOrgDetails () {
      try {
        let billCountryCopy = null
        if (this.isStateDropdown) {
          this.vmOrganisationFormData.bill_state = this.GSTStateCode[this.vmOrganisationFormData.bill_state_code]
        }
        else {
          this.vmOrganisationFormData.bill_state_code = null
        }
        if (this.oldDomain != this.vmOrganisationFormData.org_domain) {
          this.vmOrganisationFormData.org_domain_is_validated = 0
        }

        if (this.vmOrganisationFormData.bill_country) {
          billCountryCopy = this.vmOrganisationFormData.bill_country
          this.vmOrganisationFormData.bill_country = this.vmOrganisationFormData.bill_country["country_name"]
        }

        let saveOrgSaasResp = await OrgSaas.orgSaasOrganisationEdit(this, this.vmOrganisationFormData)
        ApiResponse.responseMessageDisplay(this, saveOrgSaasResp)
        this.vmOrganisationFormData.bill_country = billCountryCopy
      }
      catch (err) {
        console.error("Exception in saveSaasOrgDetails() and err: ", err)
      }
    },
    /**
     * countryDistinctList
     */
    async countryDistinctList () {
      try {
        let matchWithQuery = "distinct country"
        let countryDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (countryDistinctListResp && !countryDistinctListResp.resp_status) {
          return false
        }
        this.cvCountryOptions = countryDistinctListResp.resp_data.data
      }
      catch (err) {
        console.error("Exception occurred at countryDistinctList() and Exception:", err.message)
      }
    },
    /**
     * goToOrgDomainValidate
     */
    goToOrgDomainValidate () {
      this.$router.push(`/org_domain_validate/${this.cvOrgType}/${this.cvOrgId}`)
    },
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      await this.countryDistinctList()
      socialvue.index()
      this.orgSaasView()
    },
    /**
     * orgSaasView
     */
    async orgSaasView () {
      try {
        let orgSaasView = await OrgSaas.orgSaasOrganisationView(this, this.cvOrgId)
        if (!orgSaasView.resp_status) {
          this.toastVariant = "danger"
          this.toastMsg = orgSaasView.resp_msg
          this.showToast = true
          return
        }
        this.vmOrganisationFormData = orgSaasView.resp_data.data.org_data
        this.oldDomain = orgSaasView.resp_data.data.org_data.org_domain

        this.vmProfilePic = orgSaasView.resp_data.data.org_data.org_profile_img
        this.vmCoverPic = orgSaasView.resp_data.data.org_data.org_cover_img

        if (orgSaasView.resp_data.data.org_admin_bill_details) {
          this.vmOrganisationFormData.bill_address = orgSaasView.resp_data.data.org_admin_bill_details.bill_address
          this.vmOrganisationFormData.bill_city = orgSaasView.resp_data.data.org_admin_bill_details.bill_city
          this.vmOrganisationFormData.bill_zip_code = orgSaasView.resp_data.data.org_admin_bill_details.bill_zip_code
          this.vmOrganisationFormData.bill_state = orgSaasView.resp_data.data.org_admin_bill_details.bill_state
          this.vmOrganisationFormData.bill_state_code = orgSaasView.resp_data.data.org_admin_bill_details.bill_state_code
          this.vmOrganisationFormData.bill_country = orgSaasView.resp_data.data.org_admin_bill_details.bill_country
          this.vmOrganisationFormData.bill_gstin = orgSaasView.resp_data.data.org_admin_bill_details.bill_gstin
        }

        if (this.vmOrganisationFormData.bill_country) {
          const findCountryIndex = this.cvCountryOptions.findIndex(country => country.country_name === this.vmOrganisationFormData.bill_country)
          if (findCountryIndex >= 0) {
            this.vmOrganisationFormData.bill_country = this.cvCountryOptions[findCountryIndex]
          }
        }

        if (this.vmOrganisationFormData.bill_country && this.vmOrganisationFormData.bill_country.country_code != "IN") {
          this.isStateDropdown = false
        }
        else {
          this.isStateDropdown = true
        }

        if (this.vmOrganisationFormData.bill_state_code < 10) {
          this.vmOrganisationFormData.bill_state_code = "0" + this.vmOrganisationFormData.bill_state_code
        }
      }
      catch (err) {
        console.error("Exception in orgSaasView() and err: ", err)
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }

      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024
        this.toastVariant = "danger"
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          return false
        }

        this.toastVariant = ""
        if (imageType === "Profile Pic") {
          this.propStencilSize = {
            "width": 150,
            "height": 150
          }
          this.propCropDialogSize = "md"
        }
        else {
          this.propStencilSize = {
            "width": 970,
            "height": 240
          }
          this.propCropDialogSize = "xl"
        }
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        module_id: this.$route.params.org_id,
        module_name: this.orgType,
        image: [],
        image_type: {}
      }
    },

    /**
     * setStateFormField
     */
    setStateFormField () {
      if (this.vmOrganisationFormData.bill_country.country_code == "IN") {
        this.isStateDropdown = true
      }
      else {
        this.isStateDropdown = false
      }
    },
    /**
     * imageRemove
     */
    async imageRemove (imgType) {
      if (imgType === "profile_pic") {
        this.cvDelProfilePicLoading = true
      }
      else {
        this.cvDelCoverPicLoading = true
      }

      let img = imgType === "profile_pic" ? 2 : 3
      const imageUpdateResp = await Images.imageDelete(this, this.$route.params.org_id, img)
      await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
      if (imageUpdateResp && !imageUpdateResp.resp_status) {
        return false
      }

      if (imgType === "profile_pic") {
        this.vmProfilePic = ""
        this.cvDelProfilePicLoading = false
      }
      else {
        this.vmCoverPic = ""
        this.cvDelCoverPicLoading = false
      }
    },
    /**
    * onChangeProfileImage
    */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * imageEdit
     */
    async imageEdit (imageType) {
      if (imageType === "Profile Pic" && this.vmOrganisationProPicImage.image.length === 0) {
        return false
      }

      if (imageType === "Cover Pic" && this.vmOrganisationCoverPicImage.image.length === 0) {
        return false
      }
      try {
        let image
        if (imageType === "Profile Pic") {
          this.cvProfilePicLoading = true
          image = this.vmOrganisationProPicImage
        }
        else {
          this.cvCoverPicLoading = true
          image = this.vmOrganisationCoverPicImage
        }
        const imageUpdateResp = await Images.imageProfileUpdate(this, image)

        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        let imageResp = imageUpdateResp.resp_data
        if (imageResp && imageResp.length > 0) {
          if (imageType === "Profile Pic") {
            this.profilePicObj = imageResp[0]
          }
          else {
            this.coverPicObj = imageResp[0]
          }
          await this.addOrgToModules()
        }
        this.vmOrganisationProPicImage = Object.assign({}, this.initImageData())
      }
      catch (err) {
        console.error("Exception occurred at imagEdit() and Exception:", err.message)
      }
      finally {
        if (imageType === "Profile Pic") {
          this.cvProfilePicLoading = false
        }
        else {
          this.cvCoverPicLoading = false
        }
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * goToOrgSaasLoginPreview
     */
    goToOrgSaasLoginPreview () {
      window.open(`/login_preview/${this.cvOrgType}/${this.cvOrgId}`, "_blank")
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      if (imageType === "Profile Pic") {
        this.vmOrganisationProPicImage = Object.assign({}, this.initImageData())
        this.vmOrganisationProPicImage.image.push(
          {
            "img": img,
            "name": orgImg.name
          }
        )
        this.vmOrganisationProPicImage.image_type[orgImg.name] = 2
      }
      else {
        this.vmOrganisationCoverPicImage = Object.assign({}, this.initImageData())
        this.vmOrganisationCoverPicImage.image.push(
          {
            "img": img,
            "name": orgImg.name
          }
        )
        this.vmOrganisationCoverPicImage.image_type[orgImg.name] = 3
      }
    }
  }
}
</script>
